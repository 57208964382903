<template>
  <div class="bg">
    <div class="box">
      <v-container>
        <v-row align-content="center" justify="center">
          <v-col cols="12" class="text-center">
            <img
              alt="teros-logo"
              src="../img/logoTeros.png"
            />
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-form v-model="isValid" ref="form" lazy-validation>
              <v-row>
                <v-col v-if="isErrorMessage" cols="12">
                  <!-- Alert do Bootstrap -->
                  <div class="alert alert-danger alert-icon">
                    {{ isErrorMessage }}
                  </div>
                </v-col>
                <v-col v-if="show2FAField" cols="12">
                  <kinput
                    type="text"
                    key="org3"
                    v-model="data.codigo"
                    placeholder="Digite o código enviado em seu e-mail"
                  >
                    Código
                  </kinput>
                </v-col>
                <template v-else>
                  <v-col cols="12">
                    <kinput
                      type="text"
                      key="org1"
                      rules="required"
                      v-model="data.email"
                      placeholder="Digite seu nome de usuário"
                    >
                      Nome de usuário
                    </kinput>
                  </v-col>
                  <v-col cols="12">
                    <kinput
                      type="password"
                      key="org2"
                      rules="required|min:8"
                      v-model="data.password"
                      placeholder="Digite sua senha"
                    >
                      Senha
                    </kinput>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        block
                        color="#81B4EA"
                        :disabled="loading || !isValid"
                        @click="doLogin()"
                      >
                        <span>Entrar</span>
                        <v-icon color="#FFF" small>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                    <template v-if="!show2FAField">
                      <v-col cols="12" md="6">
                        <v-btn
                          block
                          color="#6BB4BA"
                          :disabled="loading"
                          @click="doLoginMicrosoft()"
                        >
                          <span>Entrar com</span>
                          <v-icon color="#FFF" small>mdi-microsoft-windows</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          block
                          color="#6BB4BA"
                          :disabled="loading"
                          @click="doLoginGoogle()"
                        >
                          <span>Entrar com</span>
                          <v-icon color="#FFF" small>mdi-google</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <router-link class="text-white small" :disabled="loading" :to="forgotTo">
                          Esqueceu sua senha?
                        </router-link>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" class="text-center">
            <img alt="galapos-logo" src="../img/logoGalapos.png" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "kinput": () => import("@/components/kInput.vue"),
  },
  computed: {
    forgotTo: function () {
      const { email } = this.data;
      return {
        path: '/esqueci',
        query: email ? { usuario: email } : null
      };
    },
    rules: function () {
      return {
        required: (value) => !!value,
      };
    },
  },
  data: function () {
    return {
      isErrorMessage: false,
      isValid: true,
      loading: false,
      show2FAField: false,
      showPassword: false,
      data: {
        email: "",
        password: "",
        codigo: null,
      },
    };
  },
  methods: {
    doLogin: async function () {
      try {
        this.$refs.form.validate();
        
        if (!this.isValid) {
          this.isErrorMessage = "Preencha todos os campos antes de continuar".
          return;
        }

        this.isErrorMessage = false;
        this.loading = true;
        const response = await this.koreLogin(this.data)

        if (response.error) {
          throw response
        }

        this.$store.commit("updateAuth", response);
        this.$router.push(this.$route.query.redirect || "/index");
      } catch (error) {
        this.show2FAField = [1002, 1003, 1004, 1006].includes(error.errorCode);
        this.isErrorMessage = error.error;
      } finally {
        this.loading = false;
      }
    },
    doLoginGoogle: function () {
      const clientId = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID;
      const redirectUrl = process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_REDIRECT_URL;
      const url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2f%2fwww.googleapis.com%2fauth%2fuserinfo.profile%20https%3A%2f%2fwww.googleapis.com%2fauth%2fuserinfo.email&redirect_uri=${redirectUrl}&client_id=${clientId}&response_type=code&access_type=offline`;
      window.location.assign(url);
    },
    doLoginMicrosoft: function () {
      const clientId = process.env.VUE_APP_CLIENT_ID;
      const tennantId = process.env.VUE_APP_TENNANT_ID;
      const url = `https://login.microsoftonline.com/${tennantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=token&scope=https%3A%2F%2Fgraph.microsoft.com%2FUser.Read`;
      window.location.assign(url);
    },
    togglePassword: function () {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped lang="scss">
.col {
  padding: 0 .75rem;

  &.text-center {
    padding: 1.5rem .75rem;
  }
}

.bg {
  background: url("../img/login.jpg") center;
  background-size: cover;
}

.bg,
.container,
.row {
  height: 100%;
}

.box {
  background: url("../img/circulos.png") no-repeat;
  background-position: center;
  height: 100%;
}

.v-btn {
  color: #FFF;

  .v-icon {
    margin-left: 4px;
  }
}

.v-text-field {
  & >>> .v-label {
    color: #FFF !important;
    font-weight: bold;
    top: 0;

    &.error--text {
      color: #ff3d3d !important;
    }
  }

  & >>> fieldset {
    display: none;
  }
}
</style>
